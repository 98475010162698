import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  Alert,
  Container,
  Avatar,
  Paper,
  keyframes,
  TextField,
} from "@mui/material";
import {
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  getAuth,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import "@fontsource/rethink-sans"; // Add this font to your project
import { Wordmark } from "@trusty/shared";

const config = {
  apiKey: "AIzaSyCHINpTbSWBXNgkHAQfO_Na48U0_JKbmoA",
  authDomain: "login.jointrusty.com",
};

const firebaseApp = initializeApp(config);
const auth = getAuth(firebaseApp);

// Keyframes for pulsating animation
const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(233, 176, 0, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 30px 10px rgba(233, 176, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(233, 176, 0, 0);
  }
`;

const StyledButton: React.FC<React.ComponentProps<typeof Button>> = (props) => (
  <Button
    {...props}
    sx={{
      fontFamily: "'Rethink Sans', sans-serif",
      fontWeight: 600,
      fontSize: "16px",
      padding: "10px 20px",
      backgroundColor: "rgba(229, 19, 90, 1)",
      color: "#fff",
      ":hover": {
        backgroundColor: "rgba(200, 10, 70, 1)",
      },
    }}
  >
    {props.children}
  </Button>
);

export const App: React.FC = () => {
  const [isPostInstall, setIsPostInstall] = useState(
    window.location.href.indexOf("postinstall") > -1
  );

  const [userInfo, setUserInfo] = useState<{
    name: string;
    email: string;
    photoURL: string | null;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);

  const handleGoogleLogin = () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then(async (result) => {
        const idToken = await result.user.getIdToken();
        const user = result.user;

        setUserInfo({
          name: user.displayName || "User",
          email: user.email || "N/A",
          photoURL: user.photoURL || null,
        });

        const deepLinkUrl = `trusty://login/${encodeURIComponent(idToken)}`;
        window.location.href = deepLinkUrl;
      })
      .catch((error) => {
        setError(error.message);
        console.error("Login Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEmailAuth = () => {
    setLoading(true);
    setError(null); // Clear any existing errors before starting

    const authFunc = isSignUp
      ? createUserWithEmailAndPassword
      : signInWithEmailAndPassword;

    authFunc(auth, email, password)
      .then(async (result) => {
        const idToken = await result.user.getIdToken();
        const user = result.user;

        setUserInfo({
          name: user.displayName || "User",
          email: user.email || "N/A",
          photoURL: user.photoURL || null,
        });

        const deepLinkUrl = `trusty://login/${encodeURIComponent(idToken)}`;
        window.location.href = deepLinkUrl;
      })
      .catch((error) => {
        // Enhanced error messages based on Firebase error codes
        switch (error.code) {
          case "auth/invalid-email":
            setError("Invalid email format. Please check and try again.");
            break;
          case "auth/email-already-in-use":
            setError("Email is already in use. Try signing in instead.");
            break;
          case "auth/user-not-found":
            setError(
              "User not found. Please check your credentials or sign up."
            );
            break;
          case "auth/wrong-password":
            setError("Incorrect password. Please try again.");
            break;
          case "auth/weak-password":
            setError("Password is too weak. Use at least 6 characters.");
            break;
          default:
            setError("An unexpected error occurred. Please try again later.");
            console.error("Firebase Auth Error:", error);
            break;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const emailSignInForm = (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <TextField
        fullWidth
        margin="normal"
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={handleEmailAuth}
        disabled={loading}
      >
        {loading
          ? isSignUp
            ? "Creating Account..."
            : "Signing In..."
          : isSignUp
          ? "Sign Up"
          : "Sign In"}
      </Button>
      <Typography
        variant="body2"
        sx={{
          mt: 2,
          fontFamily: "'Rethink Sans', sans-serif",
          cursor: "pointer",
          textDecoration: "underline",
        }}
        onClick={() => {
          setIsSignUp(!isSignUp);
          setError(null);
        }}
      >
        {isSignUp
          ? "Already have an account? Sign In"
          : "Don't have an account? Sign Up"}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mt: 2,
          fontFamily: "'Rethink Sans', sans-serif",
          color: "rgba(30, 30, 30, 0.6)",
        }}
      >
        Or
      </Typography>
      <Button
        fullWidth
        variant="outlined"
        sx={{
          mt: 2,
          fontFamily: "'Rethink Sans', sans-serif",
        }}
        onClick={handleGoogleLogin}
        disabled={loading}
      >
        Sign in with Google
      </Button>
    </>
  );

  const createAccountText =
    "Create your Trusty account to get access to the latest updates and to authenticate the desktop app.";

  const thankYouPage = (
    <Box textAlign="center">
      <Typography
        variant="h2"
        sx={{
          fontFamily: "'Rethink Sans', sans-serif",
          fontWeight: 700,
          fontSize: "28px",
          lineHeight: "32px",
          mb: 2,
          color: "rgba(30, 30, 30, 1)",
        }}
      >
        Thanks for installing the Trusty extension.
      </Typography>
      <Box>
          <img width="100%" src="pin-extension.gif" alt="gif showing a user pinning the Trusty extension inside of Google Chrome"></img>
          </Box>
      <Typography
        variant="body1"
        sx={{
          fontFamily: "'Rethink Sans', sans-serif",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "26px",
          mb: 3,
          color: "rgba(30, 30, 30, 0.8)",
        }}
      >
        Please pin the extension for ease of access.
      </Typography>
      <StyledButton
        onClick={() => setIsPostInstall(false)}
        sx={{ marginTop: "20px" }}
      >
        Next
      </StyledButton>
    </Box>
  );

  const loginPage = (
    <Box textAlign="center">
      {!userInfo ? (
        <Box>
          <Typography>Login or create a Trusty account.</Typography>
          <>{emailSignInForm}</>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "inline-block",
              position: "relative",
              margin: "0 auto",
              mb: 2,
              animation: `${pulse} 2s infinite`,
              borderRadius: "50%",
            }}
          >
            <Avatar
              src={userInfo.photoURL || ""}
              alt={userInfo.name}
              sx={{
                width: 120,
                height: 120,
              }}
            />
          </Box>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "'Rethink Sans', sans-serif",
              fontWeight: 700,
              fontSize: "28px",
              lineHeight: "32px",
              mb: 1,
              color: "rgba(30, 30, 30, 1)",
            }}
          >
            You're all set!
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "'Rethink Sans', sans-serif",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "26px",
              mb: 3,
              color: "rgba(30, 30, 30, 1)",
            }}
          >
            Redirecting you to Trusty Browser...
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "'Rethink Sans', sans-serif",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              mt: 2,
              color: "rgba(30, 30, 30, 0.6)",
            }}
          >
            You can close this tab.
          </Typography>
        </>
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background:
          "radial-gradient(65.22% 100% at 50% 0%, rgba(238, 171, 0, 0.4) 0%, rgba(238, 171, 0, 0) 100%)",
      }}
    >
      <Container maxWidth="sm">
        <Box
          mb={3}
          display="flex"
          justifyContent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <Wordmark />
        </Box>

        {/* Main Content */}
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            borderRadius: 2,
            maxWidth: 600,
            margin: "auto",
          }}
        >
          <Box>
          {isPostInstall ? thankYouPage : loginPage}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default App;
